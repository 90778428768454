<template>
  <div id="page-user-list">

    <b-modal
      v-model="dateRangeFilter"
      centered
      hide-footer
      title="Choose the desired date range to filter the data"
      class="bg-danger"
      size="lg"
    >
      <v-date-picker
        v-model="startEndDate"
        mode="date"
        :masks="masks"
        color="orange"
        :columns="2"
        is-range
        is-expanded
      />
    </b-modal>
    <!-- Add Record -->
    <b-modal
      v-model="popActive"
      centered
      hide-footer
      :title="`Add Record`"
      class="bg-danger"
      size="lg"
    >
      <div class="vx-col w-full">
        <b-row class="px-2">
          <h6>Fill in candidate informations</h6>
        </b-row>

        <b-row class="mx-1 my-2">
          <!-- fullname -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Full Name"
              label-for="fullname"
            >
              <b-form-input
                id="fullname"
                rows="4"
                class="w-full"
                placeholder="Full Name"
              />
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Email Address"
              label-for="email_address"
            >
              <b-form-input
                id="email_address"
                rows="4"
                class="w-full"
                placeholder="Email Address"
              />
            </b-form-group>
          </b-col>

          <!-- phone_number -->
          <b-col
            v-if="false"
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Phone Number"
              label-for="phone_number"
            >
              <b-form-input
                id="phone_number"
                rows="4"
                class="w-full"
                placeholder="Phone Number"
              />
            </b-form-group>
          </b-col>

          <!-- gender -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Gender"
              label-for="gender"
            >
              <v-select
                id="Gender"
                v-model="candidateData.gender"
                :state="
                  candidateData.gender !== 'Not Set' &&
                    candidateData.gender !== ''
                    ? true
                    : false
                "
                danger-text="Please select your gender(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                class="w-full"
                :options="allGenders"
                label="value"
                :reduce="(value) => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- highest_qualification -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Highest Qualification"
              label-for="highest_qualification"
            >
              <!-- <b-form-input
                id="highest_qualification"
                rows="4"
                class="w-full"
                placeholder="Highest Qualification"
              /> -->

              <v-select
                v-model="candidateData.highest_qualification"
                class="w-full"
                :danger="candidateData.highest_qualification.length > 0 ? false : true"
                danger-text="Please select your preffered job industry(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                :options="highestQualifications"
                label="value"
                :reduce="(value) => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- citizenship -->
          <b-col
            v-if="false"
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Citizenship"
              label-for="citizenship"
            >
              <v-select
                v-model="candidateData.citizenship"
                :danger="
                  candidateData.citizenship !== 'Not Set' &&
                    candidateData.citizenship !== ''
                    ? false
                    : true
                "
                danger-text="Please select your citizenship(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                class="w-full"
                :options="citizenship"
                label="value"
                :reduce="(value) => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- preferred_work_location -->
          <b-col
            v-if="false"
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Prefered Working Location"
              label-for="prefered_working_location"
            >
              <v-select
                v-model="candidateData.preferred_location"
                :danger="
                  candidateData.preferred_location.length > 0 &&
                    candidateData.preferred_location !== 'Not Set' &&
                    candidateData.preferred_location !== ''
                    ? false
                    : true
                "
                danger-text="Please select your preffered work location(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                class="w-full"
                multiple
                :options="prefWorkLocation"
                :reduce="(value) => value.value"
                label="value"
              />
            </b-form-group>
          </b-col>

          <!-- preferred_job_type -->
          <b-col
            v-if="false"
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Prefered Job Type"
              label-for="prefered_job_type"
            >
              <b-form-input
                id="prefered_job_type"
                rows="4"
                class="w-full"
                placeholder="Prefered Job Type"
              />
            </b-form-group>
          </b-col>

          <!-- emploment_status -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Employment Status"
              label-for="employment_status"
            >
              <v-select
                v-model="candidateData.employment_status"
                :danger="
                  candidateData.employment_status !== 'Not Set' &&
                    candidateData.employment_status !== ''
                    ? false
                    : true
                "
                danger-text="Please select your employment status(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                class="w-full"
                :options="employmentStatus"
                label="value"
                :reduce="(value) => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- preferred_position -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label-for="prefered_position"
            >
              <label>Preferred Job Position</label>
              <feather-icon
                v-b-tooltip.hover.html="' Principal Designer, Lead Designer and Designer roles include job roles such as Architect, Landscape Architect/Landscape Designer, Interior Designer, Fashion Designer, Product Designer, Furniture Designer, Graphic Designer and Interaction Designer. <br /><br /> Lead Visualiser/Lead Storyteller and Visualiser/Storyteller roles include job roles such as Renderer, Graphic Designer, Multimedia Artist, Commercial Artist, Photographer, Videographer, 2D/3D Visualiser, Creative Visualiser and Data Visualiser.  '"
                size="18"
                class="text-danger"
                style="margin-left: 1ch;"
                icon="InfoIcon"
                @click="verifyContact('email')"
              />
              <!-- <b-form-input
                id="prefered_position"
                rows="4"
                class="w-full"
                placeholder="Prefered Position"
              /> -->

              <!-- <v-select
                class="w-full"
                multiple
                danger-text="Please select your preffered job position(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                :options="preferred_position_options"
                label="value"
                :reduce="value => value.value"
              /> -->

              <!-- <select
                class="w-100"
              >
                <option :value="null" selected></option>
                <option
                  v-for="option in preferred_position_options"
                  :key="option.key"
                  :value="option.value"
                >
                  <p> {{ option.text }} </p>
                </option>
              </select> -->

              <v-select
                v-model="candidateData.preferred_position"
                class="w-full"
                multiple
                :danger="candidateData.preferred_position.length > 0 ? false : true"
                danger-text="Please select your preffered job industry(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                :options="preferred_position_options"
                label="value"
                :reduce="(value) => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- preferred_working_industry -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Prefered Industry"
              label-for="prefered_working_induxtry"
            >
              <v-select
                v-model="candidateData.preferred_industry"
                class="w-full"
                multiple
                :danger="candidateData.preferred_industry.length > 0 ? false : true"
                danger-text="Please select your preffered job industry(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                :options="prefIndustry"
                label="value"
                :reduce="(value) => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- residential_postal_code -->
          <b-col
            v-if="false"
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Residendial Postal Code"
              label-for="residential_postal_code"
            >
              <b-form-input
                id="residential_postal_code"
                rows="4"
                class="w-full"
                placeholder="Residential Postal Code"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col class="px-r my-1">
            <b-button
              class="mx-2"
              variant="primary"
              @click="$refs.uploadImgInput.click()"
            >
              <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
              />
              <input
                ref="uploadImgInput"
                type="file"
                class="hidden"
                accept="image/*"
              >
              <span class="d-none d-sm-inline">Upload Resume</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mx-1 my-2 float-right">
          <b-button variant="primary">
            Add Record
          </b-button>
        </b-row>
      </div>
    </b-modal>

    <!-- Broadcast -->
    <b-modal
      v-model="showBroadcastModal"
      centered
      hide-footer
      :title="`Broadcast`"
      class="bg-danger"
    >
      <div class="vx-col w-full">
        <b-row class="mx-1 my-2">
          <b-form-textarea
            rows="4"
            class="w-full border-danger"
            placeholder="Message"
          />
        </b-row>
        <b-row class="mx-1 my-2">
          <!-- <b-form-checkbox
            name="app_boost_gurantee"
            class="mx-2"
            value="application"
          >SMS</b-form-checkbox> -->
          <b-form-checkbox
            name="app_boost_gurantee"
            class="mx-2"
            value="application"
          >Email</b-form-checkbox>
          <b-form-checkbox
            name="app_boost_gurantee"
            class="mx-2"
            value="application"
          >Telegram</b-form-checkbox>
        </b-row>
        <b-row class="mx-1 my-2 float-right">
          <b-button variant="primary">
            Broadcast
          </b-button>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-model="activateReadMore"
      centered
      hide-footer
      title="More Details"
      class="bg-danger"
      size="lg"
    >

      <span
        style="overflow: auto; white-space: wrap;"
        class="mx-2"
        v-html="activateReadMoreValue"
      />
    </b-modal>

    <b-card class="py-1">
      <div class="d-flex justify-center w-100">
        <div
          v-if="show_switch"
          class="mx-auto"
        >
          <p
            v-if="$store.state.auth.ActiveUser.company_name === 'PersolKelly'"
            class=""
          >
            {{ which_data ? "My Data" : "All Data" }}
          </p>
          <b-form-checkbox
            v-if="$store.state.auth.ActiveUser.company_name === 'PersolKelly'"
            v-model="which_data"
            switch
            inline
            class="mx-auto w-36 h-8"
            variant="primary"
            :disabled="switch_disable"
          />
        </div>
      </div>
      <div class="d-flex flex-wrap justify-content-end mb-1">
        <!-- ITEMS PER PAGE -->

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <b-row
          class="d-flex mr-md-0 w-80 mt-1 mt-md-0"
          :class="{'justify-content-between': isTablet, 'justify-content-end': (!isTablet && !isMobile)}"
        >
          <b-col
            cols="12"
            xl="auto"
            md="auto"
            class="mt-0 mt-md-1 mt-xl-0 px-md-0 pl-sm-0 pl-md-1"
          >
            <b-form-input
              v-model="searchQuery"
              class="mb-1 mb-md-0"
              :style="`height: 40px; ${ isMobile ? 'width: 100%;' : 'min-width: 300px;;' }`"
              placeholder="Search..."
              @input="updateSearchQuery"
            />
          </b-col>
          <b-col
            cols="12"
            md="auto"
            class="mt-1 mt-xl-0 mt-md-1 px-md-0 pl-sm-0 pl-md-1"
          >
            <b-button
              v-if="!hideResume"
              id="button-with-loading"
              :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 200px' }`"
              variant="primary"
              @click="extractResumes"
            >Extract Resume & CSV</b-button>
          </b-col>
          <b-col
            v-if="!hideAction"
            id="actions"
            cols="12"
            md="auto"
            class="mt-1 mt-xl-0 mt-md-1 px-md-0 pl-sm-0 pl-md-1"
            :class="{'w-100': isMobile}"
          >
            <b-dropdown
              :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 180px' }`"
              class="cursor-pointer"
              :class="{'w-100': isMobile}"
              split
              :variant="action_view"
              :text="action_text"
              right
              :icon="action_icon"
              type="filled"
              @click="performAction"
            >
              <b-dropdown-item @click="changeAction('Actions')">
                <span class="">
                  <feather-icon
                    icon="InfoIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span class="w-32">Actions</span>
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$checkRights(this.$route.meta.section, 'write') && !['jobs', 'scheduled_jobs'].includes(getCurrentTable())"
                @click="changeAction('Add Record')"
              >
                <span class="">
                  <feather-icon
                    icon="PlusIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span class="w-32">Add Record</span>
                </span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$checkRights(this.$route.meta.section, 'delete')"
                @click="changeAction('Delete Record')"
              >
                <span class="">
                  <feather-icon
                    icon="TrashIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span class="w-32">Delete Record</span>
                </span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$checkRights(this.$route.meta.section, 'write')"
                @click="changeAction('Broadcast')"
              >
                <span class="">
                  <feather-icon
                    icon="SpeakerIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span class="w-32">Broadcast</span>
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <b-col
            cols="12"
            md="auto"
            class="mt-1 mt-md-1 mt-xl-0 px-md-0 pl-sm-0 pl-md-1"
          >
            <b-button
              variant="primary"
              :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 150px' }`"
              @click="exportToCSV"
            >Export as CSV</b-button>
          </b-col>
            <!-- v-if="['events', 'applications'].includes(getCurrentTable())" -->
          <b-col
            v-if="false"
            cols="12"
            md="auto"
            class="mt-1 mt-md-1 mt-xl-0 px-md-0 pl-sm-0 pl-md-1"
          >
            <b-button
              variant="primary"
              :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 150px' }`"
              @click="exportToCSVAndResumes"
            >Export CSV & Resumes</b-button>
          </b-col>
        </b-row>
        <!-- ACTION - DROPDOWN -->
      </div>
      <!-- <FormGen
          :form-details="formData"
          :popup-active="popActive"
          @closePopup="popActive = false"
        /> -->
      <!-- Table -->
      <div
        v-if="isLoading && firstRun"
        class="d-flex justify-content-center"
        style="height: 100px; margin-top: 30px;"
      >
        <b-spinner
          style="width: 3rem; height: 3rem;"
        />
      </div>
      <vue-good-table
        v-else
        ref="data-manager"
        mode="remote"
        :pagination-options="{
          enabled: true,
          perPage: serverParams.perPage,
          perPageDropdown: [5, 10, 20, 50, 100, 1000],
          dropdownAllowAll: false,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :is-loading.sync="isLoading"
        :total-rows="totalRecords"
        :rows="dataRows"
        :columns="columnDefs"
        style-class="vgt-table condensed bordered"
        compact-mode
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-selected-rows-change="onSelectionChange"
      >

        <template
          slot="column-filter"
          slot-scope="props"
        >
          <span
            v-if="['created_on', 'applied_on', 'date_of_birth'].includes(props.column.field)"
            class="mt-2"
          >
            <b-button
              variant="warning"
              @click="showDateRangeFilter(true, props.column.field)"
            >
              Click to filter the date
            </b-button>

          </span>

          <span
            v-else-if="props.column.field === 'applied_on'"
            class="mt-2"
          >
            <flat-pickr
              v-model="applied_on_filter"
              :config="configPicker"
              class="vgt-input"
              placeholder="Filter Added On"
              @input="(value) => updateFilters(props.column.field, value)"
            />
          </span>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->

          <span
            v-if="props.column.field === 'job_description'"
            class="scroll-container "
          >
            <p>{{ props.row.job_description && props.row.job_description.slice(0, 50) + '.....' }}</p>
            <b-badge
              variant="warning"
              class="mr-1"
              @click="readMore(props.row.job_description)"
            >Read More</b-badge>
            <!-- <p>{{ props.row.job_description && props.row.job_description.slice(0, 50) + '.....' }}</p> -->
            <!-- <p
              class="m-0 p-0 text-wrap"
              style="width: 30ch;"
            >{{ props.row.job_description }}</p> -->

          </span>
          <span
            v-else-if="props.column.field === 'job_requirements'"
            class="scroll-container "
          >
            <p>{{ props.row.job_requirements && props.row.job_requirements.slice(0, 50) + '.....' }}</p>
            <b-badge
              variant="warning"
              class="mr-1"
              @click="readMore(props.row.job_requirements)"
            >Read More</b-badge>

            <p
              v-for="row in props.row.job_requirement"
              :key="row.key"
              class="m-0 p-0 text-wrap"
              style="width: 100ch;"
            >
              {{ row }}
            </p>
          </span>

          <span
            v-else-if="props.column.field === 'job_type'"
            class="scroll-container"
          >
            <b-badge
              variant="success"
              class="mr-1"
            >{{ props.row.job_type === 'fulltime' ? 'Full-Time': 'Part-Time' }}</b-badge>
          </span>

          <span
            v-else-if="props.column.field === 'active'"
            class="scroll-container"
          >
            {{ props.row.active ? 'Yes': 'No' }}
          </span>

          <span
            v-else-if="props.column.field === 'job_interview_type'"
            class="scroll-container"
          >
            <p>{{ interviewTypes[props.row.job_interview_type] }}</p>
          </span>

          <span
            v-else-if="['employment_type', 'applicant_preferred_location', 'preferred_location',
                        'preferred_industry', 'preferred_type', 'preferred_position',
                        'industry', 'published_fields', 'post_channel'].includes(props.column.field)"
            class="scroll-container"
          >
            <div
              class="d-flex"
            >
              <b-badge
                v-for="(item, index) in props.row[props.column.field]"
                :key="props.row.id + '_' + props.column.field + '_' + index"
                variant="primary"
                class="mr-1"
              >{{ item.replace("'", "").replace('""', "").replace("'", "") }}</b-badge>
            </div>
          </span>
          <span
            v-else-if="props.column.field === 'job_questions'"
            class="scroll-container"
          >
            <div
              v-if="props.row.job_questions !== '-'"
              class="d-flex"
            >
              <span

                v-for="(item, index) in props.row.job_questions"
                :key="props.row.id + 'job_questions' + index"
                variant="warning"
                class="mr-1"
              >{{ index+1 +'. '+item }} </span>
            </div>
          </span>
          <span
            v-else-if="props.column.field === 'applicant_preferred_position'"
            class="scroll-container"
          >
            <div
              class="d-flex"
            >
              <b-badge
                v-for="(item, index) in props.row.applicant_preferred_position"
                :key="props.row.id + '_pref_position_' + index"
                variant="warning"
                class="mr-1"
              >{{ item }}</b-badge>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'applicant_preferred_industry'"
            class="scroll-container"
            style="width: 300px;"
          >
            <div

              class="d-flex"
            >
              <b-badge
                v-for="(item, index) in props.row.applicant_preferred_industry"
                :key="props.row.id + '_pref_industry_' + index"
                variant="dark"
                class="mr-1"
              >{{ item }}</b-badge>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'job_industry'"
            class="scroll-container"
            style="width: 300px;"
          >
            <div

              class="d-flex"
            >
              <b-badge
                v-for="(item, index) in props.row.job_industry"
                :key="props.row.id + '_job_industry_' + index"
                variant="warning"
                class="mr-1"
              >{{ item }}</b-badge>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'job_functions'"
            class="scroll-container"
            style="width: 300px;"
          >
            <div
              class="d-flex"
            >
              <b-badge
                v-for="(item, index) in props.row.job_functions"
                :key="props.row.id + '_function_' + index"
                variant="primary"
                class="mr-1"
              >{{ item }}</b-badge>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'interview_status'"
            class="scroll-container"
            style="width: 300px;"
          >
            <div
              v-if="props.row.interview_status"
              class="d-flex"
            >
              {{ props.row.interview_status.application_status }} - {{ props.row.interview_status.note }}
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'follow_up_answers'"
            class="scroll-container"
            style="width: 300px;"
          >
            <div
              v-for="(value, key, index) in props.row.follow_up_answers"
              :key="key"
              class="d-flex"
            >

              {{ index + 1 }}. {{ key }}: {{ value }}
            </div>
          </span>

          <span
            v-else-if="['education_info', 'applicant_education_info'].includes(props.column.field)"
            class="scroll-container"
            style="width: 300px;"
          >
            <div
              v-for="(value, key) in props.row[props.column.field]"
              :key="key"
              class="d-flex"
            >
              <b class="font-weight-bolder">
                {{ value.start_date.substring(0, 4) }} - {{ value.end_date.substring(0, 4) }}
              </b>
              &nbsp;{{ value.school_name }}&nbsp;({{value.field_of_study}})
              <br>
            </div>
          </span>

          <span
            v-else-if="['company_industry'].includes(props.column.field)"
            class="scroll-container"
            style="width: 300px;"
          >
            <!-- <b>{{ props.row.company_primary_industry.primary_ssic }}</b> <br>
            {{ props.row.company_primary_industry.primary_ssic_description }} -->
            {{ $isNotEmpty(props.row.company_industry.primary_ssic_description) ? `${props.row.company_industry.primary_ssic_description}` : 'NA' }} <br>
          </span>
         

          <!-- Column: Common -->
          <span
            v-else
            class="scroll-container"
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

      </vue-good-table>

    </b-card>

    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      :hide-header="true"
      @open-completion-form="activateTypeForm"
    />
  </div>
</template>

<script>
import { orderBy } from 'lodash'

import vSelect from 'vue-select'
import { format } from 'date-fns'
// Store Module
import { WritableStream } from 'web-streams-polyfill/ponyfill'
import streamSaver from 'streamsaver'
import FormGen from '@/layouts/components/FormGenerator.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { completionMixins } from '@/components/mixins/CompletionMixins'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { VueGoodTable } from 'vue-good-table'
import {
  BCard,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BOverlay,
  BFormCheckbox,
  BAlert,
  BFormInvalidFeedback,
  BPagination,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormRow,
} from 'bootstrap-vue'

// Cell Renderer

import { 
  allIndustries,
  highestQualifications,
  allCitizenships,
  genderOptions,
  prefWorkLocationOptions,
  employmentStatusOptions,
  allAvailabilityOptions,
  citizenshipOptions,
  optionsProfileType,
  optionsPurposeOfRegistration,
} from '@/constants/datastore'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    VueGoodTable,
    vSelect,
    FormGen,
    BCard,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BAlert,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormRow,
    BFormInvalidFeedback,

    vSelect,
    flatPickr,
  },
  mixins: [completionMixins, onResizeMixins],
  data() {
    return {
      dateRangeFilter: false,
      dateRangeField: null,
      candidateData: {
        preferred_location: [],
        preferred_industry: [],
        preferred_position: [],
        highest_qualification: '',
      },
      totalRecords: 0,
      dataRows: [],
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 5,
      },

      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
      },
      selectedRows: null,
      configPicker: {
        altInput: true,
        enableTime: false,
      },
      created_on_filter: null,
      applied_on_filter: null,

      configPicker: {
        altInput: true,
        enableTime: false,
      },
      created_on_filter: null,
      applied_on_filter: null,

      showBroadcastModal: false,
      isLoading: false,
      show_switch: false,
      switch_disable: true,
      which_data: true,
      edit_mode: false,
      blur_check: 'blur',
      action_view: 'dark',
      action_text: 'Actions',
      action_icon: 'info_outline',
      items_deletion: [],
      records: [],
      columns: [],
      main_columns: [],
      shared_columns: [],
      formData: [],
      userids: [],
      popActive: false,
      hideAction: false,
      hideResume: true,
      all_inputs: {},
      // Filter Options
      searchQuery: '',
      startEndDate: null,
      masks: {
        input: 'YYYY-MM-DD',
      },

      // field input options
      allGenders: [
        {
          text: 'Male',
          value: 'Male',
        },
        {
          text: 'Female',
          value: 'Female',
        },
        // {
        //   text: 'Others',
        //   value: 'Others',
        // },
      ],

      employmentStatus: [
        {
          text: 'Employed',
          value: 'Employed',
        },
        {
          text: 'Self-Employed',
          value: 'Self-Employed',
        },
        {
          text: 'Unemployed',
          value: 'Unemployed',
        },
      ],

      citizenship: [
        {
          text: 'Singaporean',
          value: 'Singaporean',
        },
        {
          text: 'Singaporean PR',
          value: 'Singaporean PR',
        },
        {
          text: 'Others',
          value: 'Others',
        },
      ],

      all_availability: [
        {
          text: 'Days',
          value: 'Days',
        },
        {
          text: 'Weeks',
          value: 'Weeks',
        },
        {
          text: 'Months',
          value: 'Months',
        },
      ],

      prefWorkLocation: [
        {
          text: 'North',
          value: 'North',
        },
        {
          text: 'South',
          value: 'South',
        },
        {
          text: 'Central',
          value: 'Central',
        },
        {
          text: 'East',
          value: 'East',
        },
        {
          text: 'West',
          value: 'West',
        },
      ],
      interviewTypes: {
        speed_interview: 'Virtual Speed Interview',
        virtual: 'Virtual Interview',
        physical: 'In-Person Interview',
        none: 'Not Specified',
      },

      prefIndustry: [],
      preferred_position_options: [
        { text: 'Chief Design Officer/Chief Innovation Officer/Chief Experience Officer/Chief Strategy Officer', value: 'Chief Design Officer/Chief Innovation Officer/Chief Experience Officer/Chief Strategy Officer' },
        { text: 'Head of Design Production', value: 'Head of Design Production' },
        { text: 'Head of Projects/Head of Products', value: 'Head of Projects/Head of Products' },
        { text: 'Head of Transformation', value: 'Head of Transformation' },
        { text: 'Principal Designer', value: 'Principal Designer' },
        { text: 'Principal Design Researcher', value: 'Principal Design Researcher' },
        { text: 'Principal Service Designer/Principal Experience Designer', value: 'Principal Service Designer/Principal Experience Designer' },
        { text: 'Head of Strategy', value: 'Head of Strategy' },
        { text: 'Creative Technologist', value: 'Creative Technologist' },
        { text: 'Applications Development Manager', value: 'Applications Development Manager' },
        { text: 'Design Production Manager', value: 'Design Production Manager' },
        { text: 'Design Project Manager', value: 'Design Project Manager' },
        { text: 'Product Manager', value: 'Product Manager' },
        { text: 'Design Coach/Design Accelerator', value: 'Design Coach/Design Accelerator' },
        { text: 'Lead Designer', value: 'Lead Designer' },
        { text: 'Lead Visualiser/Lead Storyteller', value: 'Lead Visualiser/Lead Storyteller' },
        { text: 'Content Strategist', value: 'Content Strategist' },
        { text: 'Lead Design Researcher', value: 'Lead Design Researcher' },
        { text: 'Lead Service Designer/Lead Experience Designer', value: 'Lead Service Designer/Lead Experience Designer' },
        { text: 'Lead Strategist/Lead Business Designer', value: 'Lead Strategist/Lead Business Designer' },
        { text: 'Design Facilitator', value: 'Design Facilitator' },
        { text: 'Designer', value: 'Designer' },
        { text: 'Visualiser/Storyteller', value: 'Visualiser/Storyteller' },
        { text: 'Content Writer', value: 'Content Writer' },
        { text: 'Design Researcher', value: 'Design Researcher' },
        { text: 'Strategist/Business Designer', value: 'Strategist/Business Designer' },
      ],

      allCitizenships: [],
      allAvailability: [],
      allPrefWorkLocation: [],
      salaryCycleOptions: [
        { text: 'Yearly', value: 'Yearly' },
        { text: 'Monthly', value: 'Monthly' },
        { text: 'Weekly', value: 'Weekly' },
        { text: 'Daily', value: 'Daily' },
        { text: 'Hourly', value: 'Hourly' },
      ],
      optionsProfileType,
      optionsPurposeOfRegistration,
      activateReadMore: false,
      activateReadMoreValue: '',
      columnDefs: [],
      firstRun: true,

      exportingCSV: false,
      exportingCSVIndex: null,
      exportingCSVAndResumes: false,
      isDataFetched: false,
    }
  },

  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      return 50
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      return 0
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        return 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1)
      },
    },
  },
  watch: {
    startEndDate(val) {
      // const column = 'applied_on'
      const column = this.dateRangeField

      const startDate = format(val.start, 'yyyy-MM-dd')
      const endDate = format(val.end, 'yyyy-MM-dd')

      const value = { from: startDate, to: endDate }
      this.updateFilters(column, value)
      this.dateRangeFilter = false
    },
    which_data(val) {
      // this.getRecords()
    },
    'serverParams.columnFilters': {
      handler(oldVal, newVal) {
      },
      deep: true,
    },

    '$route.name': function (to, from) {
      this.columnDefs = []
      this.dataRows = []
      this.firstRun = true

      this.exportingCSV = false
      this.serverParams.extractCSV = false
      this.getRecords()
      // this.updateMockData()
    },

    isMobile(val) {
      if (val) {
        this.setColumnPinnedState('_id', null)
        this.setColumnPinnedState('email', null)
        this.setColumnPinnedState('Application ID', null)
      } else {
        this.setColumnPinnedState('_id', true)
        this.setColumnPinnedState('email', true)
        this.setColumnPinnedState('Application ID', true)
      }
    },
  },
  mounted() {
    // this.gridRowHeight = 2.8 * parseFloat(getComputedStyle(document.getElementById('agGridTable')).fontSize)
    // const incompleteProfile = this.checkProfileCompletion()

    // if (incompleteProfile) {
    //   this.popupProfileQuestions = true
    // }

    // setTimeout(() => {
    //   this.showBroadcastModal = true
    // }, 3000)

    // this.$toast(
    //   {
    //     component: this.$toastContent,
    //     props: {
    //       title: 'Broadcast sent',
    //       icon: 'AlertCircleIcon',
    //       text: 'Broadcast has been sent to 11 users',
    //       variant: 'success',
    //     },
    //   },
    // )

    this.prefIndustry = orderBy(allIndustries, ['value'], ['asc'])
    this.highestQualifications = orderBy(highestQualifications, ['value'], ['asc'])
  },
  created() {
    this.prefIndustry = orderBy(allIndustries, ['value'], ['asc'])
    this.highestQualifications = orderBy(highestQualifications, ['value'], ['asc'])
    this.allGenders = orderBy(genderOptions, ['value'], ['asc'])
    this.citizenship = orderBy(citizenshipOptions, ['value'], ['asc'])
    this.allCitizenships = orderBy(allCitizenships, ['value'], ['asc'])
    this.employmentStatus = orderBy(employmentStatusOptions, ['value'], ['asc'])
    this.allAvailability = orderBy(allAvailabilityOptions, ['value'], ['asc'])
    this.allPrefWorkLocation = orderBy(prefWorkLocationOptions, ['value'], ['asc'])

    // this.updateMockData()
    this.blur_check = ''
    if (this.$store.state.auth.ActiveUser.company_name !== 'PersolKelly') {
      this.which_data = false
      this.show_switch = false
    } else {
      this.show_switch = true
      this.which_data = true
      if (
        this.$store.state.auth.ActiveUser.user_group.includes(this.$UserGroups.SUPERUSER)
      ) {
        this.which_data = false
        this.switch_disable = false
      }
    }
    if (this.$store.state.auth.ActiveUser.view_all) {
      this.switch_disable = false
    }
    this.getRecords()
  },
  destroyed() {

  },
  methods: {
    showDateRangeFilter(show, field) {
      this.dateRangeFilter = show
      this.dateRangeField = field
    },
    updateMockData() {
      const tableName = this.getCurrentTable()

      const userInfo = this.$store.state.auth.ActiveUser
      const routeName = this.$route.name
      if (userInfo.user_group.includes('admin')) {
        if (tableName === 'applications') {
          this.dataRows = [
            {
              application_id: '0002071c-3088-4df7-90ce-c534d8a2e24f',
              applicant_full_name: 'Michael Jackson',
              applicant_email_address: 'michael@gmail.com',
              applicant_gender: 'Male',
              applicant_highest_qualifications: 'Diploma',
              applicant_employment_status: 'Employed',
              applicant_preferred_job_position: 'Principal Designer',
              applicant_preferred_job_industry: 'Aerospace',
              applied_on: '3rd Jun 2023 01:02:05',
              // applied_on: '2023-06-03T01:02:05',
              job_id: '013fbcaf-ec3f-447b-aceb-78385affa0da',
              job_position: 'Sales Associate',
              employer_name: 'CHROMA FURNISHING PTE. LTD.',
              employer_uen: '201715267K',
              job_type: 'Full-Time',
              employment_type: ['Permanent'],
              working_days: '6 Days',
              working_hours: 'Shift Hours',
              minimum_salary: 'S$1',
              maximum_salary: 'S$1,000',
              salary_cycle: 'Monthly',
              working_location_address: 'Oxley Bizhub 2, 62 Ubi Road 1',
              working_location_state: 'Singapore',
              working_location_country: 'Singapore',
              working_location_postal_code: '408734',
              job_description: [
                'Our company deals with interior furnishings products and our customers are creative individuals. We brand ourselves as a creative company that offers aesthetical solutions to any interiors with our wide ranges of products from around the world.',
                'The Sales Associate is responsible for achieving sales instore/outdoor, delivering service and operations excellence. He/she also engages in innovation and productivity initiatives for the store as well as outdoor sales. In order to drive seamless customer experience across channels, he/she handles order fulfilment processes for customers in store and outdoor.  He/She operates in a fast-paced, diverse and customer-centric store environment. As the first point-of-contact in store, he/she serves as the ambassador of the organisation.  He/she is a highly motivated and service-oriented individual. He/She communicates well and thrives in a team environment.',
                'Specific Responsibilities:',
                'Mid Career Personnel/ Trainee to be involved in:',
                '1. Achieving Sales target set by company',
                '2. Maintaining the integrity of company brand and direction',
                '3. Engaging in building a good customer relationship with existing and new clientale base',
                '4. Works hand in hand with Sales Manager/Director and team to achieve the sales target in store and outdoor.',
                '5. Handles fulfilment processes of sales obtained.',
                '',
                'Competencies:',
                '1. Achieving Sales',
                '2. Achieving Service Excellence to existing and new clients',
                '3. Delivering Operation Excellence',
                '4. Executing Customers Order Fulfilment',
                '5. Engage in innovation and productivity initiatives',
              ],
              job_requirement: [
                'Our company deals with interior furnishings products and our customers are creative individuals. We brand ourselves as a creative company that offers aesthetical solutions to any interiors with our wide ranges of products from around the world.',
                'The Sales Associate is responsible for achieving sales instore/outdoor, delivering service and operations excellence. He/she also engages in innovation and productivity initiatives for the store as well as outdoor sales. In order to drive seamless customer experience across channels, he/she handles order fulfilment processes for customers in store and outdoor.  He/She operates in a fast-paced, diverse and customer-centric store environment. As the first point-of-contact in store, he/she serves as the ambassador of the organisation.  He/she is a highly motivated and service-oriented individual. He/She communicates well and thrives in a team environment.',
                'Specific Responsibilities:',
                'Mid Career Personnel/ Trainee to be involved in:',
                '1. Achieving Sales target set by company',
                '2. Maintaining the integrity of company brand and direction',
                '3. Engaging in building a good customer relationship with existing and new clientale base',
                '4. Works hand in hand with Sales Manager/Director and team to achieve the sales target in store and outdoor.',
                '5. Handles fulfilment processes of sales obtained.',
                ' ',
                'Competencies:',
                '1. Achieving Sales',
                '2. Achieving Service Excellence to existing and new clients',
                '3. Delivering Operation Excellence',
                '4. Executing Customers Order Fulfilment',
                '5. Engage in innovation and productivity initiatives',
              ],
              employer_industry: 'Retail Sales',
              vacancies: '1',
              max_applications: 'NIL',
              active_job: 'Yes',
              job_owner_email: 'Genevieve_Seow@designsingapore.org  ',
              hr_email: 'NIL',
              job_source: 'MyCareersFuture',
              screening_questions: 'NIL',
            },
          ]
        }
        if (tableName === 'jobs') {
          this.dataRows = [
            {
              job_id: '013fbcaf-ec3f-447b-aceb-78385affa0da',
              job_position: 'Sales Associate',
              employer_name: 'CHROMA FURNISHING PTE. LTD.',
              employer_uen: '201715267K',
              job_type: 'Full-Time',
              employment_type: ['Permanent'],
              working_days: '6 Days',
              working_hours: 'Shift Hours',
              minimum_salary: 'S$1',
              maximum_salary: 'S$1,000',
              salary_cycle: 'Monthly',
              working_location_address: 'Oxley Bizhub 2, 62 Ubi Road 1',
              working_location_state: 'Singapore',
              working_location_country: 'Singapore',
              working_location_postal_code: '408734',
              job_description: [
                'Our company deals with interior furnishings products and our customers are creative individuals. We brand ourselves as a creative company that offers aesthetical solutions to any interiors with our wide ranges of products from around the world.',
                'The Sales Associate is responsible for achieving sales instore/outdoor, delivering service and operations excellence. He/she also engages in innovation and productivity initiatives for the store as well as outdoor sales. In order to drive seamless customer experience across channels, he/she handles order fulfilment processes for customers in store and outdoor.  He/She operates in a fast-paced, diverse and customer-centric store environment. As the first point-of-contact in store, he/she serves as the ambassador of the organisation.  He/she is a highly motivated and service-oriented individual. He/She communicates well and thrives in a team environment.',
                'Specific Responsibilities:',
                'Mid Career Personnel/ Trainee to be involved in:',
                '1. Achieving Sales target set by company',
                '2. Maintaining the integrity of company brand and direction',
                '3. Engaging in building a good customer relationship with existing and new clientale base',
                '4. Works hand in hand with Sales Manager/Director and team to achieve the sales target in store and outdoor.',
                '5. Handles fulfilment processes of sales obtained.',
                '',
                'Competencies:',
                '1. Achieving Sales',
                '2. Achieving Service Excellence to existing and new clients',
                '3. Delivering Operation Excellence',
                '4. Executing Customers Order Fulfilment',
                '5. Engage in innovation and productivity initiatives',
              ],
              job_requirement: [
                'Our company deals with interior furnishings products and our customers are creative individuals. We brand ourselves as a creative company that offers aesthetical solutions to any interiors with our wide ranges of products from around the world.',
                'The Sales Associate is responsible for achieving sales instore/outdoor, delivering service and operations excellence. He/she also engages in innovation and productivity initiatives for the store as well as outdoor sales. In order to drive seamless customer experience across channels, he/she handles order fulfilment processes for customers in store and outdoor.  He/She operates in a fast-paced, diverse and customer-centric store environment. As the first point-of-contact in store, he/she serves as the ambassador of the organisation.  He/she is a highly motivated and service-oriented individual. He/She communicates well and thrives in a team environment.',
                'Specific Responsibilities:',
                'Mid Career Personnel/ Trainee to be involved in:',
                '1. Achieving Sales target set by company',
                '2. Maintaining the integrity of company brand and direction',
                '3. Engaging in building a good customer relationship with existing and new clientale base',
                '4. Works hand in hand with Sales Manager/Director and team to achieve the sales target in store and outdoor.',
                '5. Handles fulfilment processes of sales obtained.',
                ' ',
                'Competencies:',
                '1. Achieving Sales',
                '2. Achieving Service Excellence to existing and new clients',
                '3. Delivering Operation Excellence',
                '4. Executing Customers Order Fulfilment',
                '5. Engage in innovation and productivity initiatives',
              ],
              employer_industry: 'Retail Sales',
              vacancies: '1',
              max_applications: 'NIL',
              active_job: 'Yes',
              job_owner_email: 'Genevieve_Seow@designsingapore.org  ',
              hr_email: 'NIL',
              job_source: 'MyCareersFuture',
              screening_questions: 'NIL',
            },
          ]
        }
        if (tableName === 'users') {
          this.dataRows = [
            {
              user_id: '4c4eaf33-288a-4d25-ab56-fb9e4fb691e9',
              full_name: 'Michael Jackson',
              email_address: 'michael@gmail.com',
              gender: 'Male',
              highest_qualifications: 'Diploma',
              employment_status: 'Employed',
              preferred_job_position: 'Principal Designer',
              preferred_job_industry: 'Aerospace',
              '2fa_activated': 'Yes',
              last_signed_in: '2nd Jun 2023 15:00:45',
              created_on: '31st May 2023 12:05:49',
            },
          ]
        }
      } else if (userInfo.user_group.includes('employer')) {
        if (tableName === 'applications') {
          this.dataRows = [
            {
              application_id: '0002071c-3088-4df7-90ce-c534d8a2e24f',
              applicant_full_name: 'Michael Jackson',
              applicant_email_address: 'michael@hhqmail.com',
              applicant_gender: 'Male',
              applicant_highest_qualifications: 'Diploma',
              applicant_employment_status: 'Employed',
              // applicant_preferred_job_position: 'Principal Designer',
              // applicant_preferred_job_industry: 'Aerospace',
              applied_on: '3th Jun 2023 01:02:05',
              // applied_on: '2023-06-03T01:02:05',
              job_id: '013fbcaf-ec3f-447b-aceb-78385affa0da',
              job_position: 'Sales Associate',
              employer_name: 'CHROMA FURNISHING PTE. LTD.',
              employer_uen: '201715267K',
              job_type: 'Full-Time',
              employment_type: ['Permanent'],
              working_days: '6 Days',
              working_hours: 'Shift Hours',
              minimum_salary: 'S$1',
              maximum_salary: 'S$1,000',
              salary_cycle: 'Monthly',
              working_location_address: 'Oxley Bizhub 2, 62 Ubi Road 1',
              working_location_state: 'Singapore',
              working_location_country: 'Singapore',
              working_location_postal_code: '408734',
              job_description: [
                'Our company deals with interior furnishings products and our customers are creative individuals. We brand ourselves as a creative company that offers aesthetical solutions to any interiors with our wide ranges of products from around the world.',
                'The Sales Associate is responsible for achieving sales instore/outdoor, delivering service and operations excellence. He/she also engages in innovation and productivity initiatives for the store as well as outdoor sales. In order to drive seamless customer experience across channels, he/she handles order fulfilment processes for customers in store and outdoor.  He/She operates in a fast-paced, diverse and customer-centric store environment. As the first point-of-contact in store, he/she serves as the ambassador of the organisation.  He/she is a highly motivated and service-oriented individual. He/She communicates well and thrives in a team environment.',
                'Specific Responsibilities:',
                'Mid Career Personnel/ Trainee to be involved in:',
                '1. Achieving Sales target set by company',
                '2. Maintaining the integrity of company brand and direction',
                '3. Engaging in building a good customer relationship with existing and new clientale base',
                '4. Works hand in hand with Sales Manager/Director and team to achieve the sales target in store and outdoor.',
                '5. Handles fulfilment processes of sales obtained.',
                '',
                'Competencies:',
                '1. Achieving Sales',
                '2. Achieving Service Excellence to existing and new clients',
                '3. Delivering Operation Excellence',
                '4. Executing Customers Order Fulfilment',
                '5. Engage in innovation and productivity initiatives',
              ],
              job_requirement: [
                'Our company deals with interior furnishings products and our customers are creative individuals. We brand ourselves as a creative company that offers aesthetical solutions to any interiors with our wide ranges of products from around the world.',
                'The Sales Associate is responsible for achieving sales instore/outdoor, delivering service and operations excellence. He/she also engages in innovation and productivity initiatives for the store as well as outdoor sales. In order to drive seamless customer experience across channels, he/she handles order fulfilment processes for customers in store and outdoor.  He/She operates in a fast-paced, diverse and customer-centric store environment. As the first point-of-contact in store, he/she serves as the ambassador of the organisation.  He/she is a highly motivated and service-oriented individual. He/She communicates well and thrives in a team environment.',
                'Specific Responsibilities:',
                'Mid Career Personnel/ Trainee to be involved in:',
                '1. Achieving Sales target set by company',
                '2. Maintaining the integrity of company brand and direction',
                '3. Engaging in building a good customer relationship with existing and new clientale base',
                '4. Works hand in hand with Sales Manager/Director and team to achieve the sales target in store and outdoor.',
                '5. Handles fulfilment processes of sales obtained.',
                ' ',
                'Competencies:',
                '1. Achieving Sales',
                '2. Achieving Service Excellence to existing and new clients',
                '3. Delivering Operation Excellence',
                '4. Executing Customers Order Fulfilment',
                '5. Engage in innovation and productivity initiatives',
              ],
              employer_industry: 'Retail Sales',
              vacancies: '1',
              max_applications: 'NIL',
              active_job: 'Yes',
              job_owner_email: 'Genevieve_Seow@designsingapore.org',
              hr_email: 'NIL',
              job_source: 'MyCareersFuture',
              screening_questions: 'NIL',
            },
          ]
        }
        if (tableName === 'jobs') {
          this.dataRows = [
            {
              job_id: '013fbcaf-ec3f-447b-aceb-78385affa0da',
              job_position: 'Sales Associate',
              employer_name: 'CHROMA FURNISHING PTE. LTD.',
              employer_uen: '201715267K',
              job_type: 'Full-Time',
              employment_type: ['Permanent'],
              working_days: '6 Days',
              working_hours: 'Shift Hours',
              minimum_salary: 'S$1',
              maximum_salary: 'S$1,000',
              salary_cycle: 'Monthly',
              working_location_address: 'Oxley Bizhub 2, 62 Ubi Road 1',
              working_location_state: 'Singapore',
              working_location_country: 'Singapore',
              working_location_postal_code: '408734',
              job_description: [
                'Our company deals with interior furnishings products and our customers are creative individuals. We brand ourselves as a creative company that offers aesthetical solutions to any interiors with our wide ranges of products from around the world.',
                'The Sales Associate is responsible for achieving sales instore/outdoor, delivering service and operations excellence. He/she also engages in innovation and productivity initiatives for the store as well as outdoor sales. In order to drive seamless customer experience across channels, he/she handles order fulfilment processes for customers in store and outdoor.  He/She operates in a fast-paced, diverse and customer-centric store environment. As the first point-of-contact in store, he/she serves as the ambassador of the organisation.  He/she is a highly motivated and service-oriented individual. He/She communicates well and thrives in a team environment.',
                'Specific Responsibilities:',
                'Mid Career Personnel/ Trainee to be involved in:',
                '1. Achieving Sales target set by company',
                '2. Maintaining the integrity of company brand and direction',
                '3. Engaging in building a good customer relationship with existing and new clientale base',
                '4. Works hand in hand with Sales Manager/Director and team to achieve the sales target in store and outdoor.',
                '5. Handles fulfilment processes of sales obtained.',
                '',
                'Competencies:',
                '1. Achieving Sales',
                '2. Achieving Service Excellence to existing and new clients',
                '3. Delivering Operation Excellence',
                '4. Executing Customers Order Fulfilment',
                '5. Engage in innovation and productivity initiatives',
              ],
              job_requirement: [
                'Our company deals with interior furnishings products and our customers are creative individuals. We brand ourselves as a creative company that offers aesthetical solutions to any interiors with our wide ranges of products from around the world.',
                'The Sales Associate is responsible for achieving sales instore/outdoor, delivering service and operations excellence. He/she also engages in innovation and productivity initiatives for the store as well as outdoor sales. In order to drive seamless customer experience across channels, he/she handles order fulfilment processes for customers in store and outdoor.  He/She operates in a fast-paced, diverse and customer-centric store environment. As the first point-of-contact in store, he/she serves as the ambassador of the organisation.  He/she is a highly motivated and service-oriented individual. He/She communicates well and thrives in a team environment.',
                'Specific Responsibilities:',
                'Mid Career Personnel/ Trainee to be involved in:',
                '1. Achieving Sales target set by company',
                '2. Maintaining the integrity of company brand and direction',
                '3. Engaging in building a good customer relationship with existing and new clientale base',
                '4. Works hand in hand with Sales Manager/Director and team to achieve the sales target in store and outdoor.',
                '5. Handles fulfilment processes of sales obtained.',
                ' ',
                'Competencies:',
                '1. Achieving Sales',
                '2. Achieving Service Excellence to existing and new clients',
                '3. Delivering Operation Excellence',
                '4. Executing Customers Order Fulfilment',
                '5. Engage in innovation and productivity initiatives',
              ],
              employer_industry: 'Retail Sales',
              vacancies: '1',
              max_applications: 'NIL',
              active_job: 'Yes',
              job_owner_email: 'Genevieve_Seow@designsingapore.org',
              hr_email: 'NIL',
              job_source: 'MyCareersFuture',
              screening_questions: 'NIL',
            },
          ]
        }
        if (tableName === 'users') {
          this.dataRows = [
            {
              user_id: '4c4eaf33-288a-4d25-ab56-fb9e4fb691e9',
              full_name: 'Michael Jackson',
              email_address: 'michael@hhqmail.com',
              gender: 'Male',
              highest_qualifications: 'Diploma',
              employment_status: 'Employed',
              // preferred_job_position: 'Principal Designer',
              // preferred_job_industry: 'Aerospace',
              // '2fa_activated': 'Yes',
              // last_signed_in: '2nd Jun 2023 15:00:45',
              // created_on: '31st May 2023 12:05:49',
            },
          ]
        }
      }

      let disableSort = []
      let disableFilter = []
      let customFilter = []
      if (tableName === 'users') {
        disableSort = ['preferred_position', 'preferred_industries', 'preferred_job_type']
        disableFilter = []
        customFilter = ['created_on']
      } else if (tableName === 'jobs') {
        disableSort = ['job_questions', 'published_fields', 'employment_type']
        disableFilter = []
        customFilter = []
      } else if (tableName === 'applications') {
        disableSort = ['applicant_preferred_position', 'applicant_preferred_location', 'applicant_preferred_industry', 'applicant_preferred_job_type', 'employment_type', 'answered_questions']
        disableFilter = ['answered_questions']
        customFilter = ['applied_on']
      }

      const columns = Object.keys(this.dataRows[0])
      this.columnDefs = columns.map(item => {
        const newUserCols = {}
        // changedUser[key.charAt(0).toUpperCase() + key.slice(1)] = value;
        newUserCols.label = item.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase()).replace('Id', 'ID').replace('Uen', 'UEN')
          .replace('Hr ', 'HR ')
          .replace('2fa', '2FA')
        newUserCols.width = '300px'
        newUserCols.field = item
        newUserCols.sortable = !(disableSort.includes(item))
        newUserCols.filterOptions = {
          customFilter: (customFilter.includes(item)),
          trigger: 'enter',
          enabled: !(disableFilter.includes(item)),
          placeholder: '',
        }
        return newUserCols
      })
    },
    readMore(value) {
      this.activateReadMore = true
      this.activateReadMoreValue = value
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      // console.log(this.serverParams)
      this.getRecords()
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },

    getRecords() {
      this.isLoading = true
      let query = ''
      if (this.exportingCSV) { // if it is not for csv will append pagination details else will not paginate and will retrieve all data
        this.serverParams.extractCSV = true

        if (!this.$isEmpty(this.selectedRows)) {
          const selectedRowsIdArray = []
          this.selectedRows.forEach(row => {
            selectedRowsIdArray.push(row.application_id)
          })

          this.serverParams.selectedRows = selectedRowsIdArray
        }
      } else if (this.exportingCSVAndResumes) {
        this.serverParams.extractCSVAndResumes = true

        if (!this.$isEmpty(this.selectedRows)) {
          const selectedRowsIdArray = []
          this.selectedRows.forEach(row => {
            selectedRowsIdArray.push(row.application_id)
          })

          this.serverParams.selectedRows = selectedRowsIdArray
        }
      }
      query = `?params=${JSON.stringify(this.serverParams)}`

      const tableName = this.getCurrentTable()
      const outerThis = this

      this.$http
        .get(`/api/table/${tableName}/${this.which_data}${query}`, {
          onDownloadProgress(progressEvent) {
            const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
            const parent = document.querySelector('#exportToCSV')
            if (parent) {
              parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
            }
            if (uploadPercentage == 100) {
              setTimeout(() => {
                outerThis.$toast.dismiss(outerThis.exportingCSVIndex)
              }, outerThis.$longestTimeout)
            }
          },
        })
        .then(response => {
          if (this.exportingCSV) {
            this.columnDefs = response.data.columns.map(item => {
              const newUserCols = {}
              // changedUser[key.charAt(0).toUpperCase() + key.slice(1)] = value;
              newUserCols.label = item.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase()).replace('Id', 'ID')
              newUserCols.width = '300px'
              newUserCols.field = item
              newUserCols.filterOptions = {
                trigger: 'enter',
                enabled: true,
                placeholder: '',
              }
              return newUserCols
            })

            const headers = []

            this.columnDefs.forEach(column => {
              if (column.label.toLowerCase() === 'actions') {
                return
              }
              headers.push({
                label: column.label,
                columnName: column.field,
              })
            })
            this.$downloadCSV(response.data.records, `${tableName} - ${this.$formatDatetime(new Date())}.csv`, headers)
            this.exportingCSV = false
          } else if (this.exportingCSVAndResumes) {
            const link = document.createElement('a')
            link.href = `${this.$bucketUrl}/${response.data.s3_file_name}`
            link.setAttribute('download', response.data.s3_file_name) // or any other extension
            document.body.appendChild(link)
            link.click()
          } else {
            let disableSort = []
            let disableFilter = []
            let customFilter = []
            let filterDropdownItems = {}

            if (tableName === 'users') {
              disableSort = ['preferred_position', 'preferred_industry', 'preferred_location', 'education_info']
              disableFilter = ['education_info']
              customFilter = ['created_on', 'date_of_birth']
              filterDropdownItems = {
                gender: this.allGenders,

                // citizenship: this.allCitizenships,
                employment_status: this.employmentStatus,

                highest_qualification: this.highestQualifications,
                preferred_industry: this.prefIndustry,
                preferred_location: this.prefWorkLocation,
                // preferred_job_type: [
                //   { text: 'Part-time', value: 'parttime'},
                //   { text: 'Full-time', value: 'fulltime'}
                // ],
                profile_type: this.optionsProfileType,
                purpose_of_registration: this.optionsPurposeOfRegistration,
              }
            } else if (tableName === 'jobs') {
              disableSort = ['job_questions', 'published_fields', 'employment_type', 'industry']
              disableFilter = []
              customFilter = []
              filterDropdownItems = {
                job_type: [
                  { text: 'Part-time', value: 'parttime'},
                  { text: 'Full-time', value: 'fulltime'}
                ],
                employment_type: this.employmentTypeOptions,
                salary_cycle: this.salaryCycleOptions,
                industry: this.prefIndustry,
                active: [
                  { text: 'Yes', value: true},
                  { text: 'No', value: false}
                ],
                published_fields: [
                  { text: 'Vacancies', value: 'vacancies'},
                  { text: 'Salary', value: 'salary'},
                  { text: 'Employer', value: 'employer'},
                  { text: 'Recruiter', value: 'recruiter'},
                ],
              }
            } else if (tableName === 'applications') {
              disableSort = ['applicant_preferred_position', 'applicant_preferred_location', 'applicant_preferred_industry', 'applicant_preferred_job_type', 'employment_type', 'answered_questions', 'post_channel', 'applicant_education_info']
              disableFilter = ['answered_questions', 'applicant_education_info']
              customFilter = ['applied_on']
              filterDropdownItems = {
                applicant_gender: this.allGenders,
                applicant_preferred_location: this.prefWorkLocation,
                applicant_preferred_industry: this.prefIndustry,
                applicant_preferred_job_type: [
                  { text: 'Part-time', value: 'parttime'},
                  { text: 'Full-time', value: 'fulltime'}
                ],
                applicant_employment_status: this.employmentStatus,
                applicant_highest_qualification: this.highestQualifications,
                job_type: [
                  { text: 'Part-time', value: 'parttime'},
                  { text: 'Full-time', value: 'fulltime'}
                ],
                employment_type: this.employmentTypeOptions,

              }
            }

            if (this.columnDefs.length < 5) {
              this.columnDefs = response.data.columns.map(item => {
       
                const newUserCols = {}
                // changedUser[key.charAt(0).toUpperCase() + key.slice(1)] = value;
                newUserCols.label = item.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase()).replace('Id', 'ID')
                newUserCols.width = '300px'
                newUserCols.field = item
                newUserCols.sortable = !(disableSort.includes(item))
                newUserCols.filterOptions = {
                  customFilter: (customFilter.includes(item)),
                  trigger: 'enter',
                  enabled: !(disableFilter.includes(item)),
                  placeholder: '',

                  filterDropdownItems: filterDropdownItems[item],
                }
                return newUserCols
              })
            }

            this.dataRows = response.data.records
            this.totalRecords = response.data.totalRecords
            this.isLoading = false
          }

          const parent = document.querySelector('#exportToCSV')
          if (parent) {
            parent.querySelector('.progress-bar').style = `width: ${100}%;`

            setTimeout(() => {
              this.$toast.dismiss(this.exportingCSVIndex)
            }, this.$longestTimeout)
          }

          this.serverParams.extractCSV = false
          this.isLoading = false
          this.firstRun = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Operation Failed', 'Could not retrieve the records. Please try again later!')
          this.serverParams.extractCSV = false
          this.isLoading = false
          this.firstRun = false
        })
    },

    onColumnFilter(params) {
      if (!this.isDataFetched) {
        this.$set(this.serverParams, 'page', 1)
        this.updateParams(params)
        this.isLoading = false
        this.isDataFetched = true
      }
      setTimeout(() => {
        this.isLoading = false
        this.isDataFetched = false
      }, 3000)
    },
    paginationColFilter(current_table) {
      const normal_columns = []
      const ques_columns = []
      const visible = []
      const invisible = []
      for (const property of current_table) {
        for (const col in property) {
          if (this.shared_columns.includes(col)) {
            if (!normal_columns.includes(col)) {
              visible.push(col.toLowerCase())
              normal_columns.push(col)
            }
          } else if (!ques_columns.includes(col)) {
            visible.push(col.toLowerCase())
            ques_columns.push(col)
          }
        }
      }

      const all_columns = normal_columns.concat(ques_columns)

      for (const colm of this.main_columns) {
        if (!all_columns.includes(colm)) {
          invisible.push(colm.toLowerCase())
        }
      }

      if (visible.length === 0) {
        this.gridOptions.columnApi.setColumnsVisible(
          this.main_columns.map(v => v.toLowerCase()),
          true,
        )
      } else {
        function sleep(time) {
          return new Promise(resolve => setTimeout(resolve, time))
        }
        this.gridOptions.columnApi.setColumnsVisible(visible, true)

        sleep(500).then(() => {
          this.gridOptions.columnApi.setColumnsVisible(invisible, false)
        })
      }
    },

    extractResumes() {
      const table_name = this.getCurrentTable()
      let selected = this.gridApi.getSelectedRows()
      if (selected.length > 0) {
        selected = this.gridApi.getSelectedRows()
      } else {
        this.gridApi.selectAllFiltered()
        selected = this.gridApi.getSelectedRows()
      }
      this.userids = []
      const all_ids = []
      for (const item of selected) {
        let record_id = ''
        if ('ID' in item) {
          record_id = item.ID
        } else if ('Application ID' in item) {
          record_id = item['Application ID']
        } else {
          record_id = item._id
        }

        if (!this.userids.includes(record_id)) {
          const data = record_id
          this.userids.push(data)
        }
      }

      this.resumeData(table_name)
    },
    changeAction(text) {
      this.action_text = text
      switch (text) {
        case 'Add Record':
          this.action_view = 'success'
          this.action_icon = 'add'
          break
        case 'Delete Record':
          this.action_view = 'danger'
          this.action_icon = 'delete'

          break
        case 'Broadcast':
          this.action_view = 'info'
          this.action_icon = 'info_outline'
          break
        case 'Actions':
          this.action_view = 'dark'
          this.action_icon = 'info_outline'
          break
        default:
          this.action_view = 'dark'
          this.action_icon = 'info_outline'
      }

      this.performAction()
    },
    performAction() {
      if (this.action_text === 'Delete Record') {
        const rows = this.gridApi.getSelectedRows()
        if (!rows || !rows.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Row Selection.',
                icon: 'CheckCircleIcon',
                text: 'Please select at least one row to proceed.',
                variant: 'danger',
                position: 'center',
              },
            },
            { timeout: this.$longestTimeout },
          )
        } else {
          const selected = this.gridApi.getSelectedRows()
          let message = ''
          let count = 1
          for (const item of selected) {
            this.items_deletion.push(item._id)
            message += `\n ${item._id}\n\n`
            count += 1
          }
          this.confirmDeleteRecord(message)
        }
      } else if (this.action_text === 'Add Record') {
        this.popActive = true
      } else if (this.action_text === 'Broadcast') {
        this.showBroadcastModal = !this.showBroadcastModal
      }
    },
    exportToCSV() {
      // retrieve data from db

      this.exportingCSVIndex = this.$toast(
        {
          component: this.$toastContent,
          props: {
            id: 'toast-container-csv',
            title: 'Compiling CSV',
            icon: 'AlertCircleIcon',
            text: 'Please wait a moment',
            variant: 'warning',
            progress: 0,
            progressId: 'exportToCSV',
          },
        },
        { timeout: 300000 },
      )

      this.exportingCSV = true
      this.getRecords()
    },
    exportToCSVAndResumes() {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Compiling CSV and Resumes',
            icon: 'AlertCircleIcon',
            text: 'Please wait a moment',
            variant: 'warning',
            progress: 0,
            progressId: 'exportToCSV',
          },
        },
        { timeout: 99999999 },
      )

      this.exportingCSVAndResumes = true
      this.getRecords()
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    deleteRowData() {
      const table_name = this.getCurrentTable()
      const company = this.$store.state.auth.ActiveUser.company_name
      const del_array = []
      for (const item of this.items_deletion) {
        del_array.push(item)
      }
      this.items_deletion = []

      return new Promise((resolve, reject) => {
        this.$http
          .delete(`/api/table/${company}/${table_name}`, {
            data: { all_ids: del_array },
          })
          .then(response => {
            const cleaner = response.data

            if (cleaner.success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Operation Update',
                    icon: 'CheckCircleIcon',
                    text: cleaner.message,
                    variant: 'success',
                    position: 'center',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              this.removeVrows()
            }
            resolve(response)
          })
          .catch(error => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Operation Update',
                  icon: 'CheckCircleIcon',
                  text: 'Please try again later',
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )

            reject(error)
          })
      })
    },
    removeVrows() {
      //
    },

    updateRowData(_id, new_data, edit_column) {
      const table_name = this.getCurrentTable()
      const company = this.$store.state.auth.ActiveUser.company_name
      return new Promise((resolve, reject) => {
        this.$http
          .put(`/api/table/${company}/${table_name}`, {
            data_id: _id,
            col_data: new_data,
            col_name: edit_column,
          })
          .then(response => {
            const cleaner = response.data
            if (cleaner.success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Operation Update',
                    icon: 'CheckCircleIcon',
                    text: cleaner.message,
                    variant: 'success',
                    position: 'center',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
            resolve(response)
          })
          .catch(error => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'CheckCircleIcon',
                  text: 'Please try again later',
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )

            reject(error)
          })
      })
    },
    confirmDeleteRecord(record_names) {
      this.$bvModal
        .msgBoxConfirm(
          `You are about to delete the following records : \n ${record_names}`,
          {
            title: 'Confirm Deletion',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.deleteRowData()
          }
        })
    },
    resumeData(table) {
      const company = this.$store.state.auth.ActiveUser.company_name
      return new Promise((resolve, reject) => {
        const resume_array = []
        for (const item of this.userids) {
          resume_array.push(item)
        }
        this.gridApi.exportDataAsCsv({ onlySelected: true })

        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'File Download',
              icon: 'CheckCircleIcon',
              text: 'Zip file is downloading in the background. Please wait a few minutes if you are downloading all files.',
              variant: 'warning',
              position: 'center',
              // progress: 0,
              // progressId: 'exportResumeAndCSV',
            },
          },
          { timeout: this.$longestTimeout },
        )

        const data = {
          userids: resume_array,
          table_name: table,
        }

        const { token } = this.$store.state.auth.ActiveUser

        // this.$http
        //   .post(`${this.$http.defaults.baseURL}/api/resumes/${company}`, data, {
        //     onUploadProgress: function(progressEvent) {
        //       const uploadPercentage = parseInt(Math.round(( progressEvent.loaded / progressEvent.total ) * 100 ))
        //       const parent = document.querySelector('#exportResumeAndCSV')
        //       parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
        //     }.bind(this),
        //   })
        fetch(`${this.$http.defaults.baseURL}/api/resumes/${company}`, {
          method: 'POST',
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            const fileName = 'applications.zip'
            if (!window.WritableStream) {
              streamSaver.WritableStream = WritableStream
              window.WritableStream = WritableStream
            }
            const fileStream = streamSaver.createWriteStream(fileName)
            const readableStream = response.body

            // More optimized
            if (readableStream.pipeTo) {
              return readableStream.pipeTo(fileStream)
            }

            window.writer = fileStream.getWriter()

            const reader = response.data.getReader()

            const pump = () => reader
              .read()
              .then(res => (res.done ? writer.close() : writer.write(res.value).then(pump)))

            pump()
          })
          .catch(error => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'CheckCircleIcon',
                  text: 'Please try again later',
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )
          })
      })
    },
    dataExtractor() {
      this.isLoading = true
      const table_name = this.getCurrentTable()

      if (table_name === 'applications') {
        this.hideAction = true
        this.hideResume = false
        this.show_switch = true
      } else if (table_name === 'biweekly') {
        this.hideAction = true
        this.hideResume = true
      } else if (table_name === 'users') {
        this.hideAction = false
        this.hideResume = false
        this.show_switch = false
      } else if (table_name === 'jobs') {
        this.hideAction = false
        this.hideResume = false
        this.show_switch = true
      } else if (table_name === 'events') {
        this.hideAction = true
        this.hideResume = false
      } else if (table_name === 'events-applications') {
        this.hideAction = true
        this.hideResume = true
        this.show_switch = true
      } else if (table_name === 'scheduled_jobs') {
        this.show_switch = false
      } else {
        this.hideAction = false
        this.hideResume = true
      }
      const company = this.$store.state.auth.ActiveUser.company_name

      // server pagination
      const query = ''

      this.$http
        .get(`/api/table/${company}/${table_name}/${this.which_data}`)
        .then(response => {
          if (this.exportingCSV) {
            const headers = []
            this.columnDefs.forEach(column => {
              headers.push({
                headerName: column.headerName,
                columnName: column.field,
              })
            })

            this.$downloadCSV(response.data.records, `${table_name} - ${this.$formatDatetime(new Date())}.csv`, headers)
            this.exportingCSV = false
            this.isLoading = false
          } else {
            this.records = response.data.records

            this.columns = response.data.columns
            this.main_columns = response.data.columns

            if (response.data.pagination) {
              this.pagination = response.data.pagination // server pagination
            }

            // this.shared_columns = response.data.columns.filter(
            //   col => !col.includes('?')
            //     && !col.toLowerCase().includes('yes')
            //     && !col.toLowerCase().includes('y/n')
            //     && !col.toLowerCase().includes('please')
            //     && !col.toLowerCase().includes('nil')
            //     && !col.toLowerCase().includes('2')
            //     && !col.toLowerCase().includes('q1')
            //     && !col.toLowerCase().includes('q2'),
            // )

            // if (company === 'e2i') {
            //   this.shared_columns = [
            //     'userid',
            //     'fullname',
            //     'Nric No',
            //     'gender',
            //     "User's Phone Number",
            //     'email',
            //     "User's Employment Status",
            //     "User's Preferred Location",
            //     'highest qualification',
            //     "User's Preferred Industry",
            //     "User's Preferred Position",
            //     "User's Preferred Job Type",
            //     'citizenship',
            //     'applied On',
            //     '_id',
            //     'position',
            //     'poster Name',
            //     'poster_id',
            //     'description',
            //     'type',
            //     'requirements',
            //     'employer',
            //     'uen No',
            //     'vacancies',
            //     'salary',
            //     'days',
            //     'time',
            //     'location',
            //     'union',
            //     'coach',
            //     'team',
            //     'post_channel',
            //     'taken',
            //     'posted On',
            //     'sembawang',
            //     'closed',
            //     'interview Status',
            //     'interview Feedback',
            //     'rating_attitude',
            //     'rating_communication',
            //     'rating_cultural_fit',
            //     'rating_experience',
            //     'rating_skills_and_knowledge',
            //   ]
            // }

            this.formData = this.formArray(response.data.columns)
            this.isLoading = false
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })

      // if (!moduleDataProcessing.isRegistered) {
      //   this.$store.registerModule("dataProcessing", moduleDataProcessing);
      //   moduleDataProcessing.isRegistered = true;
      // }
      //  let company = this.$store.state.auth.ActiveUser.company_name;
      //
      // this.$store
      //   .dispatch("dataProcessing/fetchRecords", table_name, company)
      //   .then((res) => {

      //   })
      //   .catch((err) => {});
    },
    formArray(columns) {
      const array = []
      const unwanted = ['Account Created On', 'Updated On', '_id']
      for (const item of columns) {
        if (!unwanted.includes(item)) {
          array.push({
            label: this.capitalizeFirstLetter(item),
            value: '',
          })
        }
      }

      return array
    },
    getCurrentTable() {
      let table_name = ''
      const table = this.$route.name
      switch (table) {
        case 'candidates-data':
          table_name = 'users'
          break
        case 'employers-data':
          table_name = 'employers'
          break
        case 'jobs-data':
          table_name = 'jobs'
          break
        case 'scheduled-jobs-data':
          table_name = 'scheduled_jobs'
          break
        case 'applications-data':
          table_name = 'applications'
          break
        case 'bi-weekly-data':
          table_name = 'biweekly'
          break
        case 'crm-data':
          table_name = 'crm'
          break
        case 'candidates-events-applications':
          table_name = 'events-applications'
          break
        default:
          table_name = 'events'
      }
      return table_name
    },
  },
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.dropdown-button-container {
  display: flex;
  align-items: center;
  .btnx {
    border-radius: 5px 0px 0px 5px;
  }
  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
.blur {
  filter: blur(16px);
}
.hide {
  display: none;
}
</style>

<style lang="scss" scoped>
.card-body {
  // reduce gap on b-card
  padding-bottom: 0;
  padding-top: 1rem;
  padding-inline: 1rem;
}
.scroll-container {
  overflow-x: auto; /* or overflow-x: scroll; */
  white-space: nowrap; /* optional, to prevent wrapping of content */
}

</style>
